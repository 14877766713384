import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

class CheckBoxGroup extends React.Component {
  state = {
    selected: this.props.default ? this.props.default : 0,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.default !== nextState.selected) {
      return { selected: nextProps.default };
    }
    return null;
  }

  handleChange = (item) => {
    for (var i = 0; i < this.props.items.length; i++) {
      if (this.props.items[i] == item.target.value) {
        this.setState({ selected: i });
        this.props.onChange(i);
        break;
      }
    }
  };
  render() {
    return (
      <FormControl component="fieldset">
        <FormGroup>
          {this.props.items &&
            this.props.items.map((item, index) => {
              return (
                <FormControlLabel
                  key={item}
                  control={
                    <Checkbox
                      checked={this.state.selected == index}
                      onChange={this.handleChange}
                      value={item}
                    />
                  }
                  label={item}
                />
              );
            })}
        </FormGroup>
      </FormControl>
    );
  }
}

// TODO add propTypes

export default withStyles(validationFormsStyle)(CheckBoxGroup);
