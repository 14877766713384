import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";

import moment from "moment-timezone";
import DateFnsUtils from "@date-io/date-fns";
import InnerHTML from "dangerously-set-html-content";

//Dialog Related
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//New material UI
import TextField from "@material-ui/core/TextField";
import { TimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

// material ui icons
import DeleteIcon from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Card from "components/core/Card/Card.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardBody from "components/core/Card/CardBody.js";
import Button from "components/core/CustomButtons/Button.js";
import Heading from "components/core/Heading/Heading.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";

import DatetimeControl from "./DatetimeControl";
import CheckBoxGroup from "./CheckBoxGroup";

import ControlFactory from "../settings/ControlFactory";
import StatusMessage from "../common/StatusMessage";

// style for this view
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const BudgetType = ["Unlimited goal", "Daily goal"];
const DaypartType = ["Serve ads all the time", "Serve ads all the schedule"];
const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const Targeting = {
  geo: ["All Locations", "Include/Exclude Specific Locations"],
  os_version: ["All OS Versions", "Include/Exclude OS Versions"],
  app_version: ["All App Versions", "Include/Exclude Specific App Versions"],
};

const convertToTime = (minutesFromMidnightUTC) => {
  const hours = Math.floor(minutesFromMidnightUTC / 60);
  const minutes = minutesFromMidnightUTC % 60;
  return moment
    .utc()
    .hours(hours)
    .minutes(minutes)
    .seconds(0)
    .format("YYYY-MM-DD HH:mm");
};

const getMappedSettings = (adParameters, fields) => {
  if (adParameters == null || adParameters.length == 0) {
    if (fields == null || undefined) return [];
    return fields.map((item) => {
      if (item.type === "integer") {
        item.value = 0;
      } else {
        item.value = "";
      }
      return item;
    });
  }
  return fields.map((item) => {
    if (adParameters[item.name] != undefined) {
      if (item.type === "json") {
        item.value = JSON.parse(adParameters[item.name]);
      } else {
        item.value = adParameters[item.name];
      }
    } else {
      if (item.type === "integer") {
        item.value = 0;
      } else {
        item.value = "";
      }
    }
    return item;
  });
};

const versionStructurer = (version) => {
  if (version.fixed != null) {
    return `${version.fixed.major}.${version.fixed.minor}.${version.fixed.patch}`;
  } else {
    return `${version.min.major}.${version.min.minor}.${version.min.patch}-${version.max.major}.${version.max.minor}.${version.max.patch}`;
  }
};

const apiVersion = (version) => {
  if (version.fixed != null) {
    return `${version.fixed}`;
  } else {
    return `${version.min}-${version.max}`;
  }
};

const getMappedTargeting = (targeting, prevTargeting) => {
  if (targeting == null && prevTargeting == null) return {};
  if (targeting == null) return prevTargeting;
  let newTargeting = {
    os_version: {},
    app_version: {},
    geo: {},
  };
  if (targeting.app_version != null) {
    if (targeting.app_version.include != null) {
      newTargeting.app_version.incl = targeting.app_version.include
        .map((version) => versionStructurer(version))
        .join(",");
    }
    if (targeting.app_version.exclude != null) {
      newTargeting.app_version.excl = targeting.app_version.exclude
        .map((version) => versionStructurer(version))
        .join(",");
    }
  }
  if (targeting.os_version != null) {
    if (targeting.os_version.include != null) {
      newTargeting.os_version.incl = targeting.os_version.include
        .map((version) => apiVersion(version))
        .join(",");
    }
    if (targeting.app_version.exclude != null) {
      newTargeting.os_version.excl = targeting.os_version.exclude
        .map((version) => apiVersion(version))
        .join(",");
    }
  }
  if (targeting.geo != null) {
    if (targeting.geo.include != null) {
      newTargeting.geo.incl = targeting.geo.include.join(",");
    }
    if (targeting.geo.exclude != null) {
      newTargeting.geo.excl = targeting.geo.exclude.join(",");
    }
  }

  return newTargeting;
};

const getMappedSchedule = (schedule, prevSchedule) => {
  if (
    (schedule.start_time == null || schedule.start_time == null) &&
    prevSchedule == null
  )
    return {};
  if (schedule.start_time == null || schedule.start_time == null)
    return prevSchedule;
  return {
    start_time: schedule.start_time,
    end_time: schedule.end_time,
  };
};

const getMappedBudget = (budget, prevBudget) => {
  if (budget == null && prevBudget == null) return 0;
  if (budget == null) return prevBudget;
  return budget;
};

const getMappedDaypart = (daypart, prevDaypart) => {
  if (daypart == null && prevDaypart == null) return [];
  if (daypart == null) return prevDaypart;
  return daypart
    .filter((item) => {
      return (
        item.startTime != undefined &&
        item.endTime != undefined &&
        item.day != undefined
      );
    })
    .map((item) => {
      return {
        day: item.day,
        start_time: convertToTime(item.startTime),
        end_time: convertToTime(item.endTime),
      };
    });
};

class AdMixNetworkEdit extends React.Component {
  state = {
    data: this.props.data,
    settings: [],
    displayStatus: false,
    schedule: {},
    budgetType: BudgetType[0],
    budget: 0,
    all_the_time: true,
    daypart: [],
    targeting: {},
    dialogOpen: false,
    errorMessage: "",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data == null) return prevState;
    if (
      prevState.data == null ||
      nextProps.data.length !== prevState.data.length
    ) {
      let daypart = getMappedDaypart(nextProps.data.daypart, prevState.daypart);
      let all_the_time = daypart.length == 0 || !daypart[0]?.day;
      let budget = getMappedBudget(
        nextProps.data.impressions,
        prevState.budget
      );
      return {
        data: nextProps.data,
        settings: getMappedSettings(
          nextProps.data.parameters,
          nextProps.data.validationParams
        ),
        schedule: getMappedSchedule(
          {
            start_time: nextProps.data.startTime,
            end_time: nextProps.data.endTime,
          },
          prevState.schedule
        ),
        budget: budget,
        budgetType: budget > 0 ? BudgetType[1] : BudgetType[0],
        targeting: getMappedTargeting(
          nextProps.data.targeting,
          prevState.targeting
        ),
        daypart: daypart,
        all_the_time: all_the_time,
      };
    }
    return prevState;
  }

  onSubmit = () => {
    const data = {};
    data.parameters = this.normalizeSettings();
    data.impressions = this.state.budget;
    data.startTime = this.state.schedule.start_time;
    data.endTime = this.state.schedule.end_time;
    data.targeting = this.state.targeting;

    if (this.state.all_the_time) {
      this.setState({ daypart: [] });
      data.daypart = [];
    } else if (this.state.daypart.length == 0) {
      data.daypart = [];
    } else {
      for (let i = 0; i < this.state.daypart.length; i++) {
        let item = this.state.daypart[i];
        if (item.day == null) {
          this.showError("Day is missing for the daypart targeting");
          return;
        } else if (item.start_time == null || item.start_time === "") {
          this.showError("Start time is missing for the daypart targeting");
          return;
        } else if (item.end_time == null || item.end_time === "") {
          this.showError("End time is missing for the daypart targeting");
          return;
        }
      }
      data.daypart = this.state.daypart
        .filter((item) => {
          if (
            item.day == null &&
            item.start_time == null &&
            item.end_time == null
          ) {
            return false;
          }
          return true;
        })
        .map((daypart) => {
          const startDateUTC = moment.tz(
            daypart.start_time,
            "YYYY-MM-DD HH:mm",
            "UTC"
          );

          const startTimeUTC = startDateUTC.utc().format("HH:mm");
          const endDateUTC = moment.tz(
            daypart.end_time,
            "YYYY-MM-DD HH:mm",
            "UTC"
          );
          const endTimeUTC = endDateUTC.utc().format("HH:mm");

          return {
            day: daypart.day,
            start_time: startTimeUTC,
            end_time: endTimeUTC,
          };
        });
    }
    this.props.onSave(data);
    this.setState({ displayStatus: true });
  };

  showError = (errorMessage) => {
    this.setState({
      errorMessage: errorMessage,
      dialogOpen: true,
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  normalizeSettings = () => {
    return this.state.settings.map((setting) => {
      let item = { name: setting.name };
      if (setting.type === "json" && typeof setting.value === "object") {
        item.value = JSON.stringify(setting.value);
      } else if (item.type === "period") {
        item.value = "P";
        item.value += setting.value.years + "Y";
        item.value += setting.value.months + "M";
        item.value += setting.value.days + "D";
        item.value += "T";
        item.value += setting.value.hours + "H";
        item.value += setting.value.minutes + "M";
        item.value += setting.value.seconds + "S";
      } else if (item.type === "boolean") {
        item.value = setting.value ? 1 : 0;
      } else {
        item.value = setting.value;
      }
      return item;
    });
  };

  onChangeSetting = (itemName, value) => {
    const settings = [...this.state.settings];
    let index = settings.findIndex((item) => {
      return item.name === itemName;
    });
    settings[index].value = value;
    this.setState({ settings });
  };

  onChangeSchedule = (itemName, value) => {
    const schedule = this.state.schedule;
    schedule[itemName] = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.setState({ schedule });
  };

  onChangeBudgetType = (budgetType) => {
    this.setState({ budgetType: budgetType });
    if (budgetType == BudgetType[0]) {
      this.setState({ budget: 0 });
    }
  };

  onChangeBudget = (budget) => {
    let num = budget.target.value;
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (num === "" || re.test(num)) {
      this.setState({ budget: num });
    }
  };

  onChangeDaypart = (change) => {
    this.setState({ all_the_time: change == 0 });
  };

  onAddDaypart = () => {
    const daypart = this.state.daypart;
    let currentTime = moment().format("HH:mm:ss");
    daypart.push({
      start_time: currentTime,
      end_time: currentTime,
    });
    this.setState({ daypart });
  };

  onDaySelected = (item, index) => {
    const daypart = this.state.daypart;
    daypart[index].day = item;
    this.setState({ daypart });
  };

  onDaypartTime = (time, index, startTime) => {
    let t = moment(time).local().format("YYYY-MM-DD HH:mm");
    const daypart = this.state.daypart;
    if (startTime) {
      daypart[index].start_time = t;
    } else {
      daypart[index].end_time = t;
    }
    this.setState({ daypart });
  };

  onDaypartDelete = (index) => {
    const daypart = this.state.daypart;
    daypart.splice(index, 1);
    this.setState({ daypart });
  };

  onTargetingChange = (targetItem, item, incl_excl, isInclude) => {
    const targeting = this.state.targeting;
    if (item == null) {
      targeting[targetItem][isInclude ? "incl" : "excl"] = incl_excl;
    } else if (Targeting[targetItem][0] == item) {
      targeting[targetItem] = null;
    } else {
      targeting[targetItem] = {};
    }
    this.setState({ targeting });
  };

  renderDaypart = () => {
    return (
      <GridContainer>
        <Grid
          style={{ marginLeft: 40 }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm={12} md={2} />

          <Grid item={true} xs={12} sm={12} md={6}>
            Day targeting time is set and saved in UTC.
          </Grid>
        </Grid>
        {this.state.daypart != null &&
          this.state.daypart.map((item, index) => {
            return (
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                key={`DayPartItem${index}`}
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={12} md={2} />

                <Grid item={true} xs={12} sm={12} md={2}>
                  <CustomDropdown
                    hoverColor="primary"
                    buttonText={item.day ? item.day : "SELECT A DAY"}
                    onClick={(day) => this.onDaySelected(day, index)}
                    buttonProps={{
                      round: true,
                      style: { marginBottom: "0" },
                      color: "primary",
                    }}
                    dropdownList={Days}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      style={{ margin: 8 }}
                      variant="outlined"
                      value={item.start_time ? item.start_time : new Date()}
                      onChange={(change) =>
                        this.onDaypartTime(change, index, true)
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <FormLabel style={{ fontSize: 14, paddingTop: 30 }}>
                    to
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      style={{ margin: 8 }}
                      variant="outlined"
                      value={item.end_time ? item.end_time : new Date()}
                      onChange={(change) =>
                        this.onDaypartTime(change, index, false)
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <DeleteIcon
                    onClick={() => {
                      this.onDaypartDelete(index);
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        <Grid
          style={{ marginLeft: 20, marginTop: 20 }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm={12} md={2} />

          <Grid item={true} xs={12} sm={12} md={2}>
            <Button color="primary" onClick={this.onAddDaypart}>
              + ADD A DAYPART
            </Button>
          </Grid>
        </Grid>
      </GridContainer>
    );
  };

  // necessary because this gets called more than once in some cases
  goBackOnce = (function () {
    let executed = false;
    return function () {
      if (!executed) {
        executed = true;
        this.props.onBack();
      }
    };
  })();

  renderStatusMessage = () => {
    if (this.state.displayStatus) {
      setTimeout(() => {
        this.goBackOnce();
      }, 1500);

      document
        .querySelector("#dashboardMainPanel")
        .scrollTo({ top: 0, behavior: "smooth" });

      let color = "success";
      let message = "AdMix Network settings were updated successfully";
      if (this.props.error) {
        message = this.props.error.getErrorField("message").message;
        color = "warning";
      }
      return <StatusMessage message={message} color={color} />;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ paddingBottom: 200 }}>
        {this.renderStatusMessage()}
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <div style={{ color: "white" }}>AdMix Network Settings</div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              {this.state.settings != null &&
                this.state.settings.map((item, index) => {
                  let ControlComponent = ControlFactory.build(item.type);

                  let dropdownOptions = [];

                  if (
                    item.name === "type" &&
                    this.state.data.name === "Mopub"
                  ) {
                    ControlComponent = ControlFactory.build("enum");
                    dropdownOptions = ["interstitial", "banner", "native"];
                  }
                  // const required = item.hasOwnProperty("required")
                  //   ? item.required
                  //   : null;

                  if (item.type === "json" && typeof item.value === "string") {
                    item.value = JSON.parse(item.value);
                  }
                  return (
                    <React.Fragment key={`AdMixNetworkEdit:${index}`}>
                      <Grid
                        style={{ marginLeft: 20 }}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        key={item.id}
                      >
                        <Grid item xs={12} sm={12} md={2}>
                          <FormLabel>{item.name}</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <ControlComponent
                            multiline={item.type == "code_snippet"}
                            rows={item.type == "code_snippet" ? 10 : 1}
                            onChange={(value) => {
                              this.onChangeSetting(item.name, value);
                            }}
                            options={
                              dropdownOptions.length
                                ? dropdownOptions
                                : undefined
                            }
                            value={
                              !item.value && dropdownOptions.length
                                ? dropdownOptions[0]
                                : item.value
                            }
                          />
                        </Grid>
                      </Grid>
                      {item.type == "code_snippet" ? (
                        <Grid
                          style={{ marginLeft: 20 }}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          key={item.id}
                        >
                          <Grid item xs={12} sm={12} md={2}>
                            <FormLabel>Preview:</FormLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={9}>
                            {<InnerHTML html={item.value} />}
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}

              {/* ---------------- Schedule ---------------- */}
              <Grid
                style={{ marginLeft: 10 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Heading title="Schedule" />
              </Grid>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={12} md={2}>
                  <FormLabel>Schedule</FormLabel>
                </Grid>

                <Grid item={true} xs={12} sm={12} md={1}>
                  <FormLabel style={{ fontSize: 12 }}>Start date</FormLabel>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={2}>
                  <DatetimeControl
                    placeholder="Optional"
                    value={
                      this.state.schedule.start_time != null
                        ? this.state.schedule.start_time
                        : null
                    }
                    onChange={(value) => {
                      this.onChangeSchedule("start_time", value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={12} md={2} />

                <Grid item={true} xs={12} sm={12} md={1}>
                  <FormLabel style={{ fontSize: 12 }}>End date</FormLabel>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={2}>
                  <DatetimeControl
                    placeholder="Optional"
                    value={
                      this.state.schedule.end_time != null
                        ? this.state.schedule.end_time
                        : null
                    }
                    onChange={(value) => {
                      this.onChangeSchedule("end_time", value);
                    }}
                  />
                </Grid>
              </Grid>
              {/* ---------------- Budget ---------------- */}
              <Grid
                style={{ marginLeft: 10, marginTop: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Heading title="Budget" />
              </Grid>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={12} md={2}>
                  <FormLabel>Budget</FormLabel>
                </Grid>

                <Grid md={3} item>
                  <CustomDropdown
                    hoverColor="primary"
                    buttonText={this.state.budgetType}
                    onClick={this.onChangeBudgetType}
                    buttonProps={{
                      round: true,
                      style: { marginBottom: "0" },
                      color: "primary",
                    }}
                    dropdownList={BudgetType}
                  />
                </Grid>
              </Grid>
              {this.state.budgetType == BudgetType[1] ? (
                <Grid
                  style={{ marginLeft: 20, marginTop: 10 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item={true} xs={12} sm={12} md={2} />

                  <GridContainer item={true} xs={12} sm={12} md={9}>
                    <GridItem>
                      <TextField
                        style={{ margin: 8 }}
                        variant="outlined"
                        value={this.state.budget}
                        onChange={this.onChangeBudget}
                      />
                    </GridItem>
                    <GridItem style={{ margin: "auto 0" }}>
                      <FormLabel style={{ fontSize: 14 }}>
                        Daily Impressions
                      </FormLabel>
                    </GridItem>
                  </GridContainer>
                </Grid>
              ) : null}

              {/* ---------------- Daypart Targeting ---------------- */}
              <Grid
                style={{ marginLeft: 10, marginTop: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Heading title="Daypart Targeting" />
              </Grid>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item={true} xs={12} sm={12} md={2}>
                  <FormLabel>Daypart Targeting</FormLabel>
                </Grid>

                <Grid item={true} xs={12} sm={12} md={9}>
                  <CheckBoxGroup
                    default={this.state.all_the_time ? 0 : 1}
                    items={DaypartType}
                    onChange={this.onChangeDaypart}
                  />
                </Grid>
              </Grid>
              {!this.state.all_the_time && this.renderDaypart()}

              {/* ---------------- Targeting ---------------- */}
              <Grid
                style={{ marginLeft: 10, marginTop: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Heading title="Targeting" />
              </Grid>
              {this.generateTargetingItem(
                "Geo Targeting",
                this.state.targeting.geo,
                Targeting.geo,
                ["Include These Locations", "Exclude These Locations"],
                (o, i, s) => this.onTargetingChange("geo", o, i, s)
              )}
              {this.generateTargetingItem(
                "Device OS Targeting",
                this.state.targeting.os_version,
                Targeting.os_version,
                ["Include These OS", "Exclude These OS"],
                (o, i, s) => this.onTargetingChange("os_version", o, i, s)
              )}
              {this.generateTargetingItem(
                "App Version Targeting",
                this.state.targeting.app_version,
                Targeting.app_version,
                ["Include These App Versions", "Exclude These App Versions"],
                (o, i, s) => this.onTargetingChange("app_version", o, i, s)
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Grid container direction="column" alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    onClick={this.props.onBack}
                    color="rose"
                    className={classes.marginRight}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={this.onSubmit}>
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  generateTargetingItem = (
    targetingTitle,
    stateObject,
    dropdownItems,
    incl_excl,
    onSelection
  ) => {
    return (
      <GridContainer style={{ marginBottom: 20 }}>
        <Grid
          style={{ marginLeft: 20 }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item={true} xs={12} sm={12} md={2}>
            <FormLabel>{targetingTitle}</FormLabel>
          </Grid>

          <Grid item={true} xs={12} sm={12} md={9}>
            <CustomDropdown
              hoverColor="primary"
              buttonText={
                stateObject != null ? dropdownItems[1] : dropdownItems[0]
              }
              onClick={(item) => onSelection(item, null)}
              buttonProps={{
                round: true,
                style: { marginBottom: "0" },
                color: "primary",
              }}
              dropdownList={dropdownItems}
            />
          </Grid>
        </Grid>
        {stateObject != null && (
          <Grid
            style={{ marginLeft: 10 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item={true} xs={12} sm={12} md={2} />

            <Grid item xs={12} sm={12} md={9}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <GridContainer item={true} xs={12} sm={12} md={9}>
                  <GridItem>
                    <TextField
                      style={{ margin: 8 }}
                      variant="outlined"
                      value={stateObject.incl ? stateObject.incl : ""}
                      onChange={(item) =>
                        onSelection(null, item.target.value, true)
                      }
                    />
                  </GridItem>
                  <GridItem style={{ margin: "auto 0" }}>
                    <FormLabel style={{ fontSize: 14 }}>
                      {incl_excl[0]}
                    </FormLabel>
                  </GridItem>
                </GridContainer>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <GridContainer item={true} xs={12} sm={12} md={9}>
                  <GridItem>
                    <TextField
                      style={{ margin: 8 }}
                      variant="outlined"
                      value={stateObject.excl ? stateObject.excl : ""}
                      onChange={(item) =>
                        onSelection(null, item.target.value, false)
                      }
                    />
                  </GridItem>
                  <GridItem style={{ margin: "auto 0" }}>
                    <FormLabel style={{ fontSize: 14 }}>
                      {incl_excl[1]}
                    </FormLabel>
                  </GridItem>
                </GridContainer>
              </Grid>
            </Grid>
          </Grid>
        )}
      </GridContainer>
    );
  };
}

export default withStyles(validationFormsStyle)(AdMixNetworkEdit);
